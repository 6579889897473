@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/reboot';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

$font-cuprum: 'Cuprum', sans-serif;
$font-poppins: 'Poppins', sans-serif;

$primary: #4c82fb;
$body: #0E111F;
$primary-bg-color: #0D111C;
$border-color: #262B3A;

$gray: #98A1C0;
$dark-gray: #5D6785;

html {
    min-height: 100%;
    font-size: 14px;
}

.wrapper {
    min-height: 100vh;
}


// xl-device=====
@mixin fifteen-down-device {
    @media (max-width: 1500px) {
        @content;
    }
}

@mixin xxl-down-device {
    @media (max-width: 1399px) {
        @content;
    }
}

@mixin xl-down-device {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin xl-device {
    @media (min-width: 1200px) and (max-width: 1399px) {
        @content;
    }
}

@mixin lg-device {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin lg-up-device {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin lg-down-device {
    @media (max-width: 991px) {
        @content;
    }
}

// md-device============
@mixin md-device {
    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}

@mixin md-up-device {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin md-down-device {
    @media (max-width: 767px) {
        @content;
    }
}

// sm-device
@mixin sm-device {
    @media (min-width: 576px) and (max-width: 768px) {
        @content;
    }
}

@mixin sm-down-device {
    @media (max-width: 576px) {
        @content;
    }
}