.card {
  background-size: cover;
  max-width: 500px;
  transition: transform 0.1s ease;
  transform-style: preserve-3d;
  will-change: transform;
}

/* Slight parallax effect on hover */
.card:hover .content {
  transform: translateZ(12px);
}

.content {
  z-index: 1;
  transition: transform 0.3s ease;
}
