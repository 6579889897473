@import './theme.scss';

.main {
    background: $body;
    color: white;
    font-family: $font-poppins;
}

.header {
    background: $primary-bg-color;
    padding: 20px 0px !important;

    @include lg-down-device() {
        padding: 20px 0px 40px 0px !important;
    }
}

.app-container {
    max-width: 1200px;
}

.card {
    border-radius: 15px;
    border: 1px solid $border-color;
    background: $primary-bg-color;

    padding: 1rem;
}

.stream-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include md-down-device() {
        flex-direction: column;
        row-gap: 0.5rem;
    }
}

.row-info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    row-gap: 0.5rem;

    span:first-child {
        color: $gray;
        font-size: 14px;
    }

    @include md-down-device() {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}

.but {
    cursor: pointer;
    transition: .3s all ease-in-out;

    border-radius: 2rem;
    padding: 0.75rem 1rem;

    text-align: center;
}

.connect {
    background: #203059;
    font-size: 1.05rem;
    color: $primary;

    &:hover {
        color: white;
    }
}

.navbar-nav {
    align-items: center;
}

.nav-menu-wrap {
    justify-content: end;
}

.navbar-toggler {
    border: none !important;
    outline: none !important;
    font-size: 2rem !important;
    color: white !important;
    box-shadow: none !important;
}

.navbar_but {
    margin: 0rem 1.25rem;
    padding: 0.5rem 0rem;

    font-size: 1.05rem;
    font-weight: 300;
    text-align: center;
    text-decoration: none !important;
    color: $gray !important;
}

.navbar_but_focus {
    color: white !important;
}

.navbar_but_box {
    background: transparent;
    border-radius: 15px;
    padding: 0.25rem;
    transition: .5s all ease-in-out;

    &:hover {
        background: #1E2232;
    }
}

.header-title {
    font-size: 1.75rem;
    color: white;
    text-decoration: none;
    transition: .3s all ease-in-out;

    &:hover {
        color: $gray;
    }
}

.stream-progressbar {
    background-color: #203059;

    .progress-bar {
        background-color: $primary;
    }
}

.badge {
    border: 3px solid $border-color;
    border-radius: 10px;
    padding: 0.3rem 0.5rem;

    background: #1E222C;
}

.collapse-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
    height: 3rem;
    background: #293249;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: .3s all ease-in-out;

    font-size: 1.15rem;

    &:hover {
        background: #627EEA;
    }
}


.state-badge {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 12px;
    font-weight: 300;
    border: 1px solid;
    border-left: 5px solid;
}

.state-receive {
    border-color: #76D191;
}

.state-send {
    border-color: #FD766B;
}

.page-title {
    font-size: 2rem;
}

.page-description {
    font-size: 1rem;
    color: $dark-gray;
}

.info-card-body {
    border-radius: 15px;
    background: #131A2A;
    padding: 1rem;
}

.stream-info {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: $gray;

    span:first-child {
        color: $dark-gray;
        font-size: 14px;
    }
}

.cancel-stream {
    font-size: 1.05rem;
    color: $primary;

    &:hover {
        background: #203059;
        color: white;
    }
}

.create-stream-but {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #293249;
    border-radius: 100px;
    font-size: 16px;
    position: fixed;
    top: 90vh;
    right: 20px;
    cursor: pointer;
    transition: .3s all ease-in-out;

    &:hover {
        width: 160px;

        &::after {
            content: 'Create Stream';
            animation: fadein 2s;
        }
    }

    &::after {
        content: '+';
    }
}

.create-multisig-but {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #293249;
    border-radius: 100px;
    font-size: 16px;
    position: fixed;
    top: 85vh;
    right: 20px;
    cursor: pointer;
    transition: .3s all ease-in-out;

    &:hover {
        width: 160px;

        &::after {
            content: 'Create Multi-Sig';
            animation: fadein 2s;
        }
    }

    &::after {
        content: '+++';
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.create-stream-modal {
    width: 450px;
    padding: 15px;
    color: white;

    .modal-title {
        font-size: 20px;
        text-align: center;
    }

    .modal-description {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: $dark-gray;
    }

    .input-info-box {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        span:first-child {
            color: $gray;
            font-size: 13px;
        }
    }

    @include sm-down-device() {
        width: 350px;
    }
}

.ReactModal__Overlay {
    background: rgba(#000, 0.5) !important;
    z-index: 998;
    backdrop-filter: blur(10px);
}

.ReactModal__Content {
    background: #0D111C !important;
    border-radius: 15px !important;
    border: 1px solid $border-color !important;
    padding: 20px 0px !important;
    font-family: $font-poppins !important;
}

.overtime-input {
    width: 100%;
    background: #131A2A;
    border: none;
    outline: none;
    border-radius: 1rem;
    color: white;
    padding: 1rem;
}

.token_input_box {
    padding: 10px 15px;
    border-radius: 15px;
    background: #131A2A;

    .token_input {
        outline: none;
        border: none;
        background-color: transparent;
        height: 40px;
        font-size: 2rem;
        font-weight: 400;
        padding-right: 10px;
        width: 100%;
        color: white;
    }

    .selected-token-but {
        padding: 6px 18px;
        background: #293249;
        border: none;
        border-radius: 50px;
        outline: none;
        color: white;
        width: 130px;
        font-size: 1.5rem;
        font-weight: 500;

        display: flex;
        gap: 6px;
        align-items: center;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal-but {
    font-size: 1.05rem;
    color: $primary;

    &:hover {
        background: #203059;
        color: white;
    }
}

.home-title {
    font-size: 60px;
    line-height: 72px;

    font-weight: 600;
    text-align: center;
    margin: 0px 0px 24px;
    background: linear-gradient(20deg, rgb(255, 244, 207) 10%, rgb(255, 87, 218) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    max-width: 1000px;

    @include sm-down-device() {
        font-size: 32px;
        line-height: 44px;
    }

    @include md-down-device() {
        font-size: 44px;
        line-height: 56px;
    }
}

.hover-card {
    border-radius: 24px;
    padding: 2rem;

    background-color: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.19);

    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 16px 24px, rgba(0, 0, 0, 0.02) 0px 24px 32px;

    display: flex;
    flex-direction: column;
    height: 100%;
    color: $gray;
    font-size: 1.25rem;

    @include sm-down-device() {
        font-size: 1rem;
    }

    .title {
        text-align: left;
        font-size: 1.75rem;
        margin-bottom: 30px;
        color: white;

        @include sm-down-device() {
            font-size: 1.5rem;
        }
    }

    &:hover {
        border-color: $primary;
    }
}

.text-gray {
    color: $gray;
}

.start-but {
    background: linear-gradient(93.06deg, rgb(255, 0, 199) 2.66%, rgb(255, 159, 251) 98.99%);
    border: none;
    color: rgb(255, 255, 255);
    transition: all 250ms ease 0s;

    width: 250px;
    font-size: 1.2rem;

    &:hover {
        box-shadow: rgb(255, 0, 199) 0px 0px 16px 0px;
    }
}