@import '../../assets/sass/theme.scss';

.multisig-modal {
  width: 650px;
  padding: 15px;
  color: white;

  .ant-modal-content {
    background: #131A2A;
  }

  .warning-text {
    font-size: 12px;
    color: red;
  }

  .ant-form {
    color: white;

    .ant-form-item {
      .label-text {
        color: white;
      }

      label {
        height: auto;
      }

      .ant-row {
        display: block;
      }

      label {
        color: white;
      }
    }
  }
}